<template>
  <div>
    <div class="business_bg">
    </div>
    <div class="business_list" v-loading="loading">
      <div class="business_list_item moveTop" @click="toDetails(item)" v-for="item in businessList" :key="item.newsId">
        <div class="business_list_item_img">
          <img :src="item.newsCover + imgSuffix" width="100%" />
        </div>
        <div class="business_list_item_text">{{ item.title }}</div>
        <div class="business_list_item_time">
          上市时间：{{ item.year }}.{{ item.day }}
        </div>
      </div>
    </div>
    <div class="business_pagination">
      <el-pagination layout="prev, pager, next" :page-size="8" :total="query.total"
        @current-change="handleCurrentChange"></el-pagination>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "business",
  data() {
    return {
      imgSuffixTime: null,
      imgSuffix: '?x-oss-process=image/resize,m_lfit,w_300',
      businessList: ["", "", "", "", "", "", "", ""],
      loading: false,
      query: {
        page: 1,
        size: 9,
        total: 0,
      },
    };
  },
  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = ''
    }, 2000)
    window.scroll(0, 0);
    this.getList();
    this.getWXInfo() 
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: '中峪数交商贸', // 分享标题
            desc: '商贸产品', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/6ad4e29106404b348fcf73f5550622d4.png', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    toDetails(item) {
      this.$router.push({ path: "/businessDetail", query: { id: item.newsId } });
    },
    getList() {
      this.loading = true;
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?status=PUBLISHED&current=${this.query.page
          }&size=9&websiteCategoryId=5`, // 16
        )
        .then(async (res) => {
          const content = res.data.data.list;
          content.forEach((item) => {
            const updateTime = new Date(item.publishTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.title =
              item.newsTitle.length > 30
                ? item.newsTitle.substring(0, 30) + "..."
                : item.newsTitle;
          });
          this.businessList = content;
          this.query.total = res.data.data.total;
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime)
  }
};
</script>
<style scoped lang="scss">
.business_bg {
  background: url('../../static/image/business_bg.png') no-repeat;
  background-size: cover;
  background-position: 50%;
  height: 400px;
  min-width: 1240px;

  img {
    width: 100%;
  }
}

.business_list {
  margin: 80px auto 20px auto;
  width: 1200px;
  padding: 0 20px;

  .business_list_item {
    cursor: pointer;
    margin-right: 25px;
    margin-bottom: 40px;
    display: inline-block;

    .business_list_item_img {
      width: 380px;
      height: 190px;
      overflow: hidden;
    }

    .business_list_item_text {
      margin-top: 10px;
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      color: #1d1b19;
      line-height: 28px;
      max-width: 380px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .business_list_item_time {
      margin-top: 20px;
      width: 170px;
      height: 28px;
      background: #efefef;
      font-size: 14px;
      font-weight: 400;
      color: #abaeb0;
      line-height: 28px;
      text-align: center;
    }
  }

  .business_list_item:nth-child(3) {
    margin-right: 0;
  }

  .business_list_item:nth-child(6) {
    margin-right: 0;
  }

  .business_list_item:nth-child(9) {
    margin-right: 0;
  }
}

.business_pagination {
  min-width: 1240px;
  text-align: center;
  margin-bottom: 64px;
}

::v-deep .el-pager li.active {
  width: 28px;
  height: 28px;
  background: #9e1307;
  border-radius: 2px;
  color: #ffffff;
  line-height: 28px;
}

::v-deep .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #565759;
  line-height: 28px;
  padding: 0;
  margin: 0 4px;
  font-weight: 400;
}

::v-deep .el-pager li.active+li {
  border: 1px solid #e5e5e5;
}
</style>
